
.product-view-report {
  table {
    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      th {
        white-space: nowrap;
        vertical-align: middle;
        width: 100%;
        min-width: 200px;
        border-bottom: 15px var(--body-bg) solid;

        &:first-child {
          position: sticky;
          left: 0;
          background-color: var(--table-tr-bg);
        }
      }
    }

    /deep/ td {
      border-bottom: 15px var(--body-bg) solid;
      text-align: right;

      &.total {
        max-width: 400px;
        white-space: break-spaces;
      }
      &:first-child {
        position: sticky;
        left: 0;
        background-color: var(--table-tr-bg);
        font-weight: 600;
        text-align: start;
      }
      .td-row {
        display: flex;
        justify-content: center;
        border-right: 1px #98a6ad solid;
        padding: 0 5px;

        .td-row-left {
          text-align: end;
          width: 100%;
        }
        .td-row-right {
          text-align: start;
          vertical-align: top;
          color: #98a6ad;
          font-size: 10px;
          margin-left: 2px;
          width: 50px;
        }
      }
      &.td-break {
        .td-row {
          border-right: none;
        }
      }
    }

    /deep/ tr {
      &.total {
        background-color: var(--table-tr-bg);
        td {
          background-color: var(--table-tr-bg);
          font-weight: 600;
        }
      }
      &.header {
        td {
          font-weight: 600;
        }
      }
    }

    /deep/ .td-break {
      border-right: 15px var(--body-bg) solid;
      &:last-child {
        border-right: none;
      }
    }
  }

  /deep/ .product-box {
    .img-block {
      height: 118px;
    }
  }
}
