
.image-cell-renderer {
  //width: 140px;
  //height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .box-img {
    background: none;
    padding: 0;
    border: none;
    max-width: 100%;
    max-height: 100%;
  }
}
