
body {
  &.dark-mode {
    .product-dropdown-input {
      /deep/ input {
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        right 0.9rem center/8px 10px no-repeat var(--custom-select-bg);
      }

      .list {
        li {
          &.active, &:hover {
            border: 2px var(--menu-item-hover-bg) solid;
          }
        }
      }
    }
  }
}

.product-dropdown-input {
  /deep/ input {
    padding-right: calc(1.5em + 0.9rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.9rem center/8px 10px no-repeat var(--custom-select-bg);
  }

  .input-group-text {
    background-color: #fff;
    cursor: pointer;
  }

  .list {
    max-height: calc(-300px + 100vh);
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    padding-left: 0;
    overflow-y: auto;
    list-style-type: none;
    color: var(--custom-select-color);
    background: var(--custom-select-bg);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    margin: 0 12px;
    border-radius: 12px;

    li {
      padding: 5px;
      font-size: .875rem;
      cursor: pointer;

      &.active, &:hover {
        border: 2px #dedede solid;
      }

      .product-image {
        width: 40px;
        height: 60px;
        min-width: 40px;

        .box-img {
          background: none;
          padding: 0;
          border: none;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
