
.charts-box {
  //overflow: auto;
  white-space: nowrap;
  &__item {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }
}
